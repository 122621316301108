import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment} from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ModelosService {
  modelosTrabajo = {
    nombre: [
      'XTZ125',
      'AG200',
      //'YBR125G',
      'YBR125ED'
    ],
    descripcion: [
      'xtz125',
      'ag200',
      //'ybr125g',
      '#'
    ],
    imagen: [
      'm000',
      'm000',
      //'m000',
      'm000'
    ],
    ruta: [
      '/xtz125',
      '/ag200',
      //'/ybr125g',
      '/#'
    ]
  };

  constructor(private http: HttpClient) { }
  // Servicio que obtiene todos los modelos
  getAll() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/yamaha/motos/all`;
    return this.http.get<any>(url, httpOptions);
  }
  // Servicio que obtiene los datos de cada modelo, recibe el modelo key
  getModelo(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/yamaha/motos/modelo/${id}`;
    return this.http.get<any>(url, httpOptions);
  }
  // Servicio que obtiene todos los modelos
  getAllLF() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/yamaha/linea-de-fuerza/all`;
    return this.http.get<any>(url, httpOptions);
  }
  // Servicio que obtiene los datos de cada modelo, recibe el modelo key
  getModelosLF(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/yamaha/linea-de-fuerza/modelo/${id}`;
    return this.http.get<any>(url, httpOptions);
  }
 
}
